import Blockies from './blockies-component';
import { EthAddress, Tooltip } from "./Utils";
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { blacklist } from './blacklist.js';

var React = require('react');
const { getProfile, getVerifiedAccounts } = require('3box/lib/api');

class MyThreebox extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			profile: null,
			accounts: null,
			avatar: this.getAvatar(this.props.address, null, null)
		}
	}
	
	getTooltipName(profile) {
		var name = ""
		if (profile.name) { // Name available
			name += profile.name + " " }
		if (profile.emoji) { // Emoji available
			name += profile.emoji }
		
		return name
	}
	
	getTooltipBody(profile, accounts) {
		var body = [];
		
		if (profile.location) { // Location available, override default
			body.push(<p key="location"><FontAwesomeIcon icon="map-marker-alt" fixedWidth /> { profile.location }</p>) }
		
		if (accounts && accounts.twitter && accounts.twitter.username) { // Twitter available, override default
			body.push(<p key="twitter"><FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth /> <a href={ 'https://twitter.com/' + accounts.twitter.username } target='_blank' rel="noopener noreferrer">{ accounts.twitter.username[0] === '@' ? (accounts.twitter.username) : ('@' + accounts.twitter.username) }</a></p>) }
		
		if (accounts && accounts.github && accounts.github.username) { // GitHub available, override default
			body.push(<p key="github"><FontAwesomeIcon icon={['fab', 'github']} fixedWidth /> <a href= {'https://github.com/' + accounts.github.username } target='_blank' rel="noopener noreferrer">{ accounts.github.username }</a></p>) }
		
		if (accounts && accounts.email && accounts.email.username) { // Email available, override default
			body.push(<p key="email"><FontAwesomeIcon icon="envelope" fixedWidth /> <a href={ 'mailto:' + accounts.email.username }>{ accounts.email.username }</a></p>) }
		
		if (profile.website) { // Website available, override default
			body.push(<p key="website"><FontAwesomeIcon icon="globe" fixedWidth /> <a href={ "https://" + profile.website } target='_blank' rel="noopener noreferrer">{ profile.website }</a></p>) }

		return body
	}
	
	getAvatar(address, profile, accounts) {
		var avatar;
		if (profile === null || profile.status === "error") {
			// No profile (no hover)
			avatar = <Blockies opts={{ seed: address, spotcolor: "#888", color: "#EEE", bgcolor: "#AAA" }} key={ address } />
		} else if (profile.image && profile.image[0] && profile.image[0].contentUrl['/']) {
			// Profile has an image (hover)
			const contentUrl = "https://ipfs.infura.io:5001/api/v0/cat?arg=" + profile.image[0].contentUrl['/']
			avatar = <img src={ contentUrl } className="avatar" alt="" />
		} else if (this.getTooltipName(profile) === "" && this.getTooltipBody(profile, accounts).length === 0) {
			// Profile does not have content (no hover)
			avatar = <Blockies opts={{ seed: address, spotcolor: "#888", color: "#EEE", bgcolor: "#AAA" }} key={ address } />
		} else {
			// Profile has content (hover)
			avatar = <Blockies opts={{ seed: address }} key={ address + "-color" } />
		}
		
		return avatar
	}
	
	updateProfile() {
		const address = this.props.address
		
		// Reset the state
		if (this._isMounted) {
			this.setState({
				profile: null,
				accounts: null,
				avatar: this.getAvatar(address, null, null)
			});
		}
		
		if (blacklist.indexOf(address) >= 0) {
			return
		}
		
		// Load the profile
		getProfile(address).then(profile => {
			// If profile loaded successfully => Request verified accounts (twitter, etc)
			// Update everything at once to avoid unnecessary rendering
			if (profile && profile.status !== 'error') {
				getVerifiedAccounts(profile).then(accounts => {
					if (this._isMounted) {
						this.setState({
							profile: profile,
							accounts: accounts,
							avatar: this.getAvatar(address, profile, accounts)
						});
					}
				});
			} else {
			// Otherwise just update profile
				if (this._isMounted) {
					this.setState({
						profile: profile,
						avatar: this.getAvatar(address, profile, this.state.accounts)
					});
				}
			}
		});
	}
	
	componentDidMount = () => {
		this._isMounted = true;
		this.updateProfile();
	}
	
	componentWillUnmount = () => {
		this._isMounted = false;
	}
	
	componentDidUpdate = (prevProps) => {
		if(this.props.address !== prevProps.address) {
			this.updateProfile()
		}
	}

	render() {
		// If the profile is loaded from 3Box => show the information with hover
		// Default to a basic profile (blockies + address) with no hover
		
		const address = this.props.address
		const profile = this.state.profile
		const accounts = this.state.accounts
		const avatar = this.state.avatar
		
		if (profile === null || profile.status === "error") {
			// Profile has not loaded from 3Box
			return <EthAddress address={ address } avatar={ avatar } />;
		} else if (this.getTooltipName(profile) === "" && this.getTooltipBody(profile, accounts).length === 0) {
			// Profile has loaded but has no content
			return <EthAddress address={ address } avatar={ avatar } />;
		} else {
			// Profile has loaded from 3Box & has content
			var tooltip = <Tooltip address={ address } avatar={ avatar } name={ this.getTooltipName(profile) } body={ this.getTooltipBody(profile, accounts) } />
			return (
				<>
				<EthAddress address={ address } avatar={ avatar } />
				<ReactTooltip
					place="bottom"
					effect="solid"
					clickable={ true }
					id={ address }
					getContent={() => { return tooltip }}
					offset={{ top: 0, left: 147}}
				/>
				</>
			);
		}
	}
}

export default MyThreebox;