var React = require('react');
var blockies = require("./blockies");

class Blockies extends React.Component {
	getOpts () {
	  	var opts = {
  			seed: this.props.opts.seed || "foo",
			size: this.props.opts.size || 6,
			scale: this.props.opts.scale || 16,
  		}
  	
	  	if (this.props.opts.spotcolor) { opts['spotcolor'] = this.props.opts.spotcolor }
  		if (this.props.opts.color) { opts['color'] = this.props.opts.color }
	  	if (this.props.opts.bgcolor) { opts['bgcolor'] = this.props.opts.bgcolor }
  	
  		return opts;
	}
	
	componentDidMount() {
		this.draw();
	}
	
	draw() {
		blockies.render(this.getOpts(), this.canvas);
	}

	render() {
		return React.createElement("canvas", {ref: canvas => this.canvas = canvas});
	}
}

export default Blockies;