import React from "react";
import logo from './logo.png';

export const Header = () => (
	<div className="header-center">
		<a href="https://foam.space/" target="_blank" rel="noopener noreferrer">
			<img className="header-logo" src={logo} alt="FOAM"/>
		</a>
		<div className="header-links">
			<a className="header-button" href="https://map.foam.space" target="_blank" rel="noopener noreferrer">FOAM Map</a>
		</div>
	</div>
);

export const Title = () => <h1>FOAM Map Leaderboard<span className="beta">Beta</span></h1>;

export const Subtitle = ({text}) => <h2>{ text }</h2>;

export const Button = ({ 
  text, 
  onClick, 
  value, 
  active
}) => (
	<button
		onClick={ onClick }
		className={ active }
		value={ value }
	>
	{text}
	</button>
);

export const EthAddress = ({
	address,
	avatar
}) => (
	<div className="ethAddress" data-tip='' data-for={ address }>
		{ avatar }
		<span className="account">{ address }</span>
	</div>
);

export const Tooltip = ({
	address,
	avatar,
	name,
	body
}) => (
	<div>
	<div className="tooltip-header">
		<div className="tooltip-avatar">{ avatar }</div>
		<p>{ name }</p>
	</div>
	{ body.length > 0 ? (<div className="tooltip-body">{ body }</div>) : (<></>) }
	<div className="tooltip-footer">
		<a href={ "https://3box.io/" + address } target="_blank" rel="noopener noreferrer">View on 3Box &rarr;</a>
	</div>
	</div>
);

export const Search = ({
	search_query,
	onChange
}) => (
	<div className="search">
		<input type="search" placeholder="Search Cartographers" autoComplete="off" value={ search_query } onChange={ onChange } />
	</div>
);

export const Footer = ({text}) => (
	<p className="footer">
		{ text }<br />
		Profiles provided by <a href="https://3box.io" target="_blank" rel="noopener noreferrer">3Box.io</a>.<br /><br />
		&copy;2019 Foamspace Corp.
	</p>
);
