import React, { Component } from 'react';

import { Header, Title, Subtitle, Button, Search, Footer } from "./Utils";
import MyThreebox from './threebox-component';

import ReactTable from "react-table";
import "react-table/react-table.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMapMarkerAlt, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(faMapMarkerAlt, faEnvelope, faGlobe)
library.add(fab)

class App extends Component {
	state = {
		config: [],
		timeRange: "total",
		subtitle: '\u00A0',
		updated: '\u00A0',
		footer: {},
		data: [],
		loading: false,
		searchQuery: "",
		filtered: []
	};

	componentDidMount() {
		this.loadConfig();
	}
    
	loadConfig = () => {
    		const storage_url = "https://storage.googleapis.com/beaming_magnet/"
    		const url_config = storage_url + "config.json"
    		
		this.setState({
			loading: true
		})
		
		Promise.all([
			fetch(url_config).then(resp => resp.json())
		]).then(([config]) => {
			this.setState({
				config: config,
				subtitle: config[this.state.timeRange].subtitle,
				updated: config[this.state.timeRange].updated,
				footer: config[this.state.timeRange].footer
			})
			this.loadData();
		});
    	}
    
	loadData = (timeRange = this.state.timeRange) => {
		const storage_url = "https://storage.googleapis.com/beaming_magnet/"
		const url_table_rows = storage_url + this.state.config[timeRange].filename
		
		this.setState({
			loading: true,
			subtitle: '\u00A0',
			updated: '\u00A0',
			footer: {}
		})
	
		Promise.all([
			fetch(url_table_rows).then(resp => resp.json())
		]).then(([table_rows]) => {
			this.setState({
					data: table_rows,
					loading: false,
					timeRange: timeRange,
					subtitle: this.state.config[timeRange].subtitle,
					updated: this.state.config[timeRange].updated,
					footer: this.state.config[timeRange].footer
			})
			
		});
	}
    
	handleButtonClick = (event) => {
		this.loadData(event.target.value);
	}

	handleChange = (event) => {
		this.setState({
			searchQuery: event.target.value,
			filtered: { accessor: "account", value: event.target.value }
		});
	}

	render() {
		var data = this.state.data
		const searchQuery = this.state.searchQuery
		if (searchQuery) { // filter data
			data = data.filter(row => {
				return row.account.includes(this.state.searchQuery.toLowerCase())
		  	})
		}
		const footer = this.state.footer
		const columns = [{
			Header: 'Cartographer',
			accessor: 'account',
			sortable: false,
			minWidth: 200,
			headerClassName: 'center',
			Cell: props => {
				return <MyThreebox address={ props.original.account } />
			},
			Footer: <span className="padding-left">{ footer['text'] }</span>
		},{
			Header: 'Points',
			accessor: 'points',
			style: { textAlign: 'right' },
			width: 70,
			Cell: props => {
				if(props.value === 0) { return "-" } 
				else { return props.value.toLocaleString() }
			},
			Footer: Object.keys(footer).length > 0 ? parseInt(footer['points']).toLocaleString() : ''
		},{
			Header: 'Challenges',
			accessor: 'challenges',
			style: { textAlign: 'right' },
			width: 105,
			Cell: props => {
				if(props.value === 0) { return "-" } 
				else { return props.value.toLocaleString() }
			},
			Footer: Object.keys(footer).length > 0 ? parseInt(footer['challenges']).toLocaleString() : ''
		},{
			Header: 'Votes',
			accessor: 'votes',
			style: { textAlign: 'right' },
			width: 75,
                	Cell: props => {
                		if(props.value === 0) { return "-" } 
                		else { return props.value.toLocaleString() }
                	},
			Footer: Object.keys(footer).length > 0 ? parseInt(footer['votes']).toLocaleString() : ''
		},{
			Header: 'Map Rewards',
			accessor: 'rewards',
			style: { textAlign: 'right' },
			width: 105,
			Cell: props => {
				if(props.value === 0) { return "-" } 
				else { return props.value.toLocaleString() }
			}
		}]
		const loading = this.state.loading
		const config = this.state.config
		const timeRange = this.state.timeRange
		const subtitle = this.state.subtitle
		const updated = this.state.updated

		return (
		  <><div className="header-container">
		      <Header />
		      </div>
		      <div className="container">
			<Title />
			<Subtitle text={ subtitle } />
		  	<div className="group">
		  		<div className="buttons">
					{ Object.keys(config).map( (key, index) => {
						var active = '';
						if (key === timeRange) { active='active' }
						return <Button text={ config[key]['button'] } active={ active } onClick={ this.handleButtonClick } value={ key } key={ key } />;
					}) }
				</div>
				<Search value={ searchQuery } onChange={ this.handleChange } />
			</div>
			<div className="table">
				<ReactTable 
					data={ data } 
					columns={ columns }
					resizable={ false }
					defaultSorted={ [{id: "points", desc: true}] } 
					defaultSortDesc={ true }
					showPageSizeOptions={ false }
					defaultPageSize={ 10 }
					loading={ loading }
					filtered={this.state.filtered}
					className="-highlight"
					/>	
			</div>
			<Footer text={ updated } />
		  </div></>
		);
	}
}

export default App;
